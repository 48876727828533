@font-face {
  font-family: 'Aileron';
  src: url('fonts/Aileron-Thin.woff2') format('woff2'),
       url('fonts/Aileron-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aileron';
  src: url('fonts/Aileron-UltraLight.woff2') format('woff2'),
       url('fonts/Aileron-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aileron';
  src: url('fonts/Aileron-Light.woff2') format('woff2'),
       url('fonts/Aileron-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aileron';
  src: url('fonts/Aileron-Regular.woff2') format('woff2'),
       url('fonts/Aileron-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aileron';
  src: url('fonts/Aileron-SemiBold.woff2') format('woff2'),
       url('fonts/Aileron-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aileron';
  src: url('fonts/Aileron-Bold.woff2') format('woff2'),
       url('fonts/Aileron-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aileron';
  src: url('fonts/Aileron-Heavy.woff2') format('woff2'),
       url('fonts/Aileron-Heavy.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aileron';
  src: url('fonts/Aileron-Black.woff2') format('woff2'),
       url('fonts/Aileron-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aileron';
  src: url('fonts/Aileron-ThinItalic.woff2') format('woff2'),
       url('fonts/Aileron-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aileron';
  src: url('fonts/Aileron-UltraLightItalic.woff2') format('woff2'),
       url('fonts/Aileron-UltraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aileron';
  src: url('fonts/Aileron-LightItalic.woff2') format('woff2'),
       url('fonts/Aileron-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aileron';
  src: url('fonts/Aileron-Italic.woff2') format('woff2'),
       url('fonts/Aileron-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aileron';
  src: url('fonts/Aileron-SemiBoldItalic.woff2') format('woff2'),
       url('fonts/Aileron-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aileron';
  src: url('fonts/Aileron-BoldItalic.woff2') format('woff2'),
       url('fonts/Aileron-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aileron';
  src: url('fonts/Aileron-HeavyItalic.woff2') format('woff2'),
       url('fonts/Aileron-HeavyItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aileron';
  src: url('fonts/Aileron-BlackItalic.woff2') format('woff2'),
       url('fonts/Aileron-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
