.callout {
  @apply border-4 rounded-1 py-8 px-4 sm:px-8;
}

.callout-red {
  @apply border-red-light shadow-sharp-red-dark;
}

.callout-yellow {
  @apply border-yellow-light shadow-sharp-yellow-dark;
}

.callout-green {
  @apply border-green-light shadow-sharp-green-dark;
}

.callout-blue {
  @apply border-blue-light shadow-sharp-blue-dark;
}

.callout-purple {
  @apply border-purple-light shadow-sharp-purple-dark;
}

.callout-beige {
  @apply border-beige-light shadow-sharp-beige-dark;
}

.callout-darkgray {
  @apply border-darkgray-light shadow-sharp-darkgray-dark;
}

.callout-lightgray {
  @apply border-lightgray-light shadow-sharp-lightgray-dark;
}
