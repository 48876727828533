.button {
  @apply py-2 px-8 rounded-1 cursor-pointer;
}

.button:hover {
  @apply animate-pulse;
}

.button-red {
  @apply bg-red-light shadow-sharp-red-dark;
}

.button-yellow {
  @apply bg-yellow-light shadow-sharp-yellow-dark;
}

.button-green {
  @apply bg-green-light shadow-sharp-green-dark;
}

.button-blue {
  @apply bg-blue-light shadow-sharp-blue-dark;
}

.button-purple {
  @apply bg-purple-light shadow-sharp-purple-dark;
}

.button-beige {
  @apply bg-beige-light shadow-sharp-beige-dark;
}

.button-darkgray {
  @apply bg-darkgray-light shadow-sharp-darkgray-dark;
}

.button-lightgray {
  @apply bg-lightgray-light shadow-sharp-lightgray-dark;
}
